<template>
    <div class="default-container">
        <Navbar />
        <div class="default-container__inner">
            <NavMenu :links-data="linksData" class="nav__wrapper" />
            <router-view class="content__wrapper" />
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/Common/Navbar/Navbar.vue';
    import NavMenu from '@/components/Common/NavMenu/NavMenu.vue';

    export default {
        name: 'AdminLayout',
        components: { Navbar, NavMenu },

        computed: {
            linksData() {
                return [
                    { name: 'adminDishes', title: this.$t('navbar.dishes'), hidden: false },
                    { name: 'adminMenu', title: this.$t('navbar.weeksMenu'), hidden: false },
                    { name: 'adminMealKits', title: this.$t('navbar.mealKits'), hidden: false },
                    { name: 'createMealKit', title: this.$t('navbar.mealKits'), hidden: true },
                    { name: 'allAdminMealKits', title: this.$t('navbar.mealKits'), hidden: true },
                    { name: 'updateMealKit', title: this.$t('navbar.mealKits'), hidden: true },
                    { name: 'updateIndividualOrder', title: this.$t('navbar.mealKits'), hidden: true },
                    { name: 'adminDiscounts', title: this.$t('navbar.discounts'), hidden: false },
                    { name: 'adminCheckOutSettings', title: this.$t('navbar.checkoutOrderSettings'), hidden: false },
                    { name: 'adminOrderManagement', title: this.$t('navbar.orderManagement'), hidden: false },
                    { name: 'allAdminCheckOuts', title: this.$t('navbar.orderManagement'), hidden: true },
                    { name: 'adminCheckOut', title: this.$t('navbar.orderManagement'), hidden: true },
                    { name: 'adminKitOrderSettings', title: this.$t('navbar.kitOrderSettings'), hidden: false },
                    { name: 'adminBlogArticles', title: this.$t('navbar.blogArticles'), hidden: false },
                    { name: 'allAdminBlogArticles', title: this.$t('navbar.blogArticles'), hidden: true },
                    { name: 'createBlogArticle', title: this.$t('navbar.blogArticles'), hidden: true },
                    { name: 'updateBlogArticle', title: this.$t('navbar.blogArticles'), hidden: true },
                    { name: 'adminFAQ', title: this.$t('navbar.faq'), hidden: false },
                    { name: 'adminPages', title: this.$t('navbar.adminsPages'), hidden: false},
                    { name: 'otherSettings', title: this.$t('navbar.otherSettings'), hidden: false },
                    { name: 'adminsProfile', title: this.$t('navbar.adminsProfile'), hidden: false },
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .default-container {
        position: relative;

        min-height: 100vh;

        padding-top: 75px;
        padding-bottom: 70px;

        @include media($md) {
            padding-bottom: 0;
        }

        &__inner {
            padding: 0 12px;

            .nav__wrapper {
                margin-top: 15px;
            }
        }
    }

    @include media($lg) {
        .default-container {
            &__inner {
                @include row-align-start;

                padding: 0 55px;

                .nav__wrapper {
                    padding-top: 70px;

                    width: 345px;
                }

                .content__wrapper {
                    padding-top: 70px;

                    padding-left: 1px;
                    padding-right: 1px;

                    width: 100%;
                    overflow-y: scroll;
                    height: calc(100vh - 76px);

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    @include media($xxl) {
        .default-container {
            &__inner {
                padding: 0 155px;
            }
        }
    }
</style>
